import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InteriorPageHeader from "../components/template-partials/global-components/interior-page-header"
import aboutBG from "../images/1.0_backgroundheader.jpg";
import hiSkivolo from "../images/7.1.1_hiskivolo.png"
import Section from "../components/template-partials/global-components/section"

import legalRound from "../images/legal-round.jpg";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404 Error" description="This page cannot be found." />

      <InteriorPageHeader backgroundImage={aboutBG} roundImage={legalRound} alt="404 Page Not Found">
        <h1 className="section__heading section__heading--green h1">404 Page Not Found</h1>
      </InteriorPageHeader>

      <Section className="interior-page lg_pb-5">
        <div className="row row--inner">
          <div className="columns">
            <div className="row align-middle small-col-reverse">
              <div className="columns large-4 small-10 small-offset-1 large-offset-0">
                <img src={hiSkivolo} alt="skivolo says hello at the 404 page"/>
              </div>
              <div className="columns small-12 large-8">
                <h2 className="h2 tc-viridian jost-med">Sorry, this page is no longer part of the neuroblastoma treatment journey.</h2>
                <p><Link to="/">Please allow Skivolo to help you get back on the right path to the homepage!</Link></p>
              </div>
            </div>
          </div>
        </div>
      </Section>


    </Layout>
  )
}

export default NotFoundPage
